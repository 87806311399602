function encode(data) {
	return data
		.map((pair) => encodeURIComponent(pair[0]) + '=' + encodeURIComponent(pair[1]))
		.join('&')
}

export const netlifyFormHandle = (e, redirectUrl = '/thanks') => {
	e.preventDefault();
	const form = e.currentTarget;
	const formData = new FormData(form);
	return fetch(form.action, {
		method: 'POST',
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: encode([
			['form-name', form.getAttribute('name')],
			...formData.entries(),
		]),
	}).then(() => {
		if (redirectUrl) {
			window.location.href = redirectUrl;
		}
		return formData;
	});
}