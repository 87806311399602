import React, {useState, useEffect} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import {Button, Form, Modal} from "react-bootstrap";
import {netlifyFormHandle} from "../utils/form";

const moodMap = {
	angry: '😠',
	sad: '🙁',
	neutral: '😐',
	positive: '😀',
	amazing: '🤩',
};
const MoodReport = () => {
	const [loading, setLoading] = useState(false);
	const [mood, setMood] = useState('');
	const [feedback, setFeedback] = useState('');
	const search = typeof window !== 'undefined' ? window.location.search : '';
	useEffect(() => {
		const urlParams = new URLSearchParams(search);
		setMood(urlParams.get('mood'));
	}, [search])
	const handleSignUp = (e) => {
		setLoading(true);
		return netlifyFormHandle(e);
	};
	return (
		<Layout>
			<SEO title="Thank you!"/>
			<div className="w-100 h-100 bg-light py-5">
				<Modal.Dialog>
					<Form onSubmit={handleSignUp} action="/" method="POST" data-netlify="true" name="mood-report">
						<Modal.Body>
							<h3>Your current mood</h3>
							<p className="text-muted"><span role="img" aria-label="hello">👋</span> Thank you for letting us know about how you feel at the moment.</p>
							<input type="hidden" value="mood-report" name="form-name"/>
							<div className="d-flex mb-5 align-items-center justify-content-center">
								{Object.keys(moodMap).map(key => (
									<div className="mr-2 display-4" style={mood === key ? {} : {
										opacity: 0.3,
										textShadow: '0 0 0 #f2f2f2',
									}}>
										<span role="img" aria-label={key}>{moodMap[key]}</span>
									</div>
								))}
							</div>
							<Form.Group controlId="formName">
								<Form.Control as="textarea" rows={3} onChange={e => setFeedback(e.target.value)} required name="feedback" placeholder="Tell us more (optional)"/>
							</Form.Group>
						</Modal.Body>
						{feedback && (
							<Modal.Footer className="justify-content-center">
								<Button className="w-100" disabled={loading} variant="success" type="submit">{loading ? (
									<>
										<span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"/>
										Loading...
									</>
								) : 'Submit'}</Button>
							</Modal.Footer>
						)}
					</Form>
				</Modal.Dialog>
			</div>
		</Layout>
	);
}

export default MoodReport
